<template>
  <div>
    <div class="row">
      <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4', 'scenario-list']"> 
        <!-- 물질별 위험도 추정 -->
        <c-table
          ref="table"
          title="LBL0001205"
          :columns="grid.columns"
          :gridHeight="setheight"
          :data="process.charmEstimationScenarios"
          :merge="scenarioViewGroup==='L' || scenarioViewGroup==='C' ? grid.merge : []"
          :filtering="false"
          :customTrClass="setTrClass"
          :columnSetting="false"
          :usePaging="false"
          :isExcelDown="scenarioViewGroup==='L'"
          :isFullScreen="scenarioViewGroup==='L'"
          :editable="editable&&!disabled"
          @table-data-change="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-update-btn 
                name="updateBtn"
                :data="estimationUpdateBtnData"
                :btnEditable="estimationBtnEditable"
                :flagCondition="flagCondition"
                @back="back"
              />
              
              <!-- 이전평가결과조회 -->
              <c-btn 
                v-show="isCompared" 
                label="LBL0001166" 
                icon="compare_arrows" 
                @btnClicked="compareScenario" />
              <c-btn 
                v-if="editable&&!disabled&&scenarioViewGroup==='L'" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="process.charmEstimationScenarios"
                mappingType="PUT"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveScenario"
                @btnCallback="saveScenarioCallback" />
            </q-btn-group>
          </template>
          <template v-slot:suffixTitle>
            <q-btn-group outline class="scenario-view-group">
              <q-btn 
                :outline="scenarioViewGroup!=='L'"
                :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="view_list"
                @click="listView"
              >
                <q-tooltip>
                  <!-- 목록형 -->
                  {{$label('LBL0001167')}}
                </q-tooltip>
              </q-btn>
              <q-btn 
                :outline="scenarioViewGroup!=='C'"
                :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="space_dashboard"
                @click="cardView"
              >
                <q-tooltip>
                  <!-- 카드형 -->
                  {{$label('LBL0001168')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='item'">
              <q-item class="card-scenario-list"> 
                <q-item-section>
                  <q-item-label class="scenario-card-title">{{`${props.row['casNo']} `}}</q-item-label>
                </q-item-section>

                <q-item-section side style="padding-right: 0px;">
                  <q-btn 
                    unelevated  dense
                    color="primary"
                    label="▶"
                    @click="getDetail(props.row.ramCharmEstimationAssessScenarioId)" />
                </q-item-section>
              </q-item>
            </template>
          </template>
        </c-table>
      </div>
      <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
        <q-form ref="editForm">
          <div class="row">
            <div class="col-6">
              <!-- 노출수준 측정 -->
              <c-card title="LBL0001128" class="cardClassDetailForm" style="height:625px">
                <template slot="card-button">
                  <q-btn-group outline >
                    <c-btn 
                      v-show="editable&&!disabled&&isCardSelect" 
                      :isSubmit="isSaveUnit"
                      :url="saveUnitUrl"
                      :param="scenario"
                      mappingType="PUT"
                      label="LBLSAVE" 
                      icon="save"
                      @beforeAction="saveScenarioUnit"
                      @btnCallback="saveScenarioUnitCallback" />
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-4">
                    <!-- 1순위 -->
                    <p class="font">{{$label('LBL0001206')}}</p>
                    <!-- 유소견자 발생여부 -->
                    <c-checkbox
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isFlag="true"
                      label="LBL0001129"
                      name="suspectUserFlag"
                      @input="cardDataChange('suspectUserFlag')"
                      v-model="scenario.suspectUserFlag"
                    />
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <!-- 2순위 작업환경측정 -->
                      <p class="font2">{{$label('LBL0001207')}}</p>
                      <!-- 측정치(A) -->
                      <c-text
                        :disabled="disabled||!selectScenario"
                        :editable="editable"
                        label="LBL0001131"
                        name="measValue"
                        v-model="scenario.measValue"
                        @dataChange="cardDataChange('measValue')"
                        style="margin-top:20px">
                      </c-text>
                    </div>
                      <!-- 노출기준(B) -->
                    <div class="col-4">
                      <c-text
                        :disabled="disabled||!selectScenario"
                        :editable="editable"
                        label="LBL0001132"
                        name="exposureStandard"
                        v-model="scenario.exposureStandard"
                        @dataChange="cardDataChange('exposureStandard')"
                        style="margin-top:41px">
                      </c-text>
                    </div>
                      <!-- A/B(%) -->
                    <div class="col-4">
                      <c-text
                        :editable="false"
                        label="LBL0001133"
                        name="exposureMeasValue"
                        v-model="scenario.exposureMeasValue"
                        style="margin-top:41px">
                      </c-text>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <!-- 3순위 하루 취급량, 비산성/휘발성 -->
                      <p class="font2">{{$label('LBL0001208')}}</p>
                      <!-- 함유량 -->
                      <c-text
                        :disabled="disabled||!selectScenario"
                        :editable="editable"
                        label="LBL0001135"
                        name="limitRepval"
                        v-model="scenario.limitRepval"
                        @dataChange="cardDataChange('limitRepval')"
                        style="margin-top:20px">
                      </c-text>
                    </div>
                    <div class="col-3">
                      <!-- 일취급량 -->
                      <c-text
                        :editable="false"
                        label="LBL0001136"
                        name="dailyVolume"
                        v-model="scenario.dailyVolume"
                        style="margin-top: 41px;"
                        >
                      </c-text>
                    </div>
                    <div class="col-3">
                      <!-- 단위 -->
                      <c-text
                        :editable="false"
                        label="LBLUNIT"
                        name="volumeUnitName"
                        v-model="scenario.volumeUnitName"
                        style="margin-top: 41px;">
                      </c-text>
                    </div>
                    <div class="col-3">
                      <!-- 일취급 수준 -->
                      <c-text
                        :editable="false"
                        label="LBL0001137"
                        name="dailyLevel"
                        v-model="scenario.dailyLevel"
                        style="margin-top: 41px;">
                      </c-text>
                    </div>
                  </div>
                  <div class="col-3">
                    <!-- 발생형태 -->
                    <c-select
                      type="edit"
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001138"
                      name="maleficenceType"
                      v-model="scenario.maleficenceType"
                      codeGroupCd="MALEFICENCE_TYPE"
                      itemText="codeName"
                      itemValue="code"
                      @input="cardDataChange('maleficenceType')"
                    >
                    </c-select>
                  </div>
                  <div class="col-3" v-if="isMaleficenceType1">
                    <!-- 비산성 -->
                    <c-select
                      type="edit"
                      :disabled="disabled||!selectScenario||maleficenceTypeFlag"
                      :editable="editable"
                      label="LBL0001139"
                      name="maleficenceGrade"
                      v-model="scenario.maleficenceGrade"
                      codeGroupCd="MALEFICENCE_GRADE"
                      itemText="codeName"
                      itemValue="code"
                    >
                    </c-select>
                  </div>
                  <div class="col-9" v-if="scenario.maleficenceType !== null && !maleficenceTypeFlag">
                  </div>
                  <div class="col-3" v-if="isMaleficenceType2">
                    <!-- 끓는점 -->
                    <c-text
                      :disabled="disabled||!selectScenario||!maleficenceTypeFlag"
                      :editable="editable"
                      label="LBL0001140"
                      name="boilingPoint"
                      v-model="scenario.boilingPoint"
                      @dataChange="cardDataChange('boilingPoint')">
                    </c-text>
                  </div>
                  <div class="col-3" v-if="isMaleficenceType2">
                    <!-- 휘발성 -->
                    <c-text
                      :editable="false"
                      label="LBL0001141"
                      name="volatilityGradeName"
                      v-model="scenario.volatilityGradeName">
                    </c-text>
                  </div>
                  <div class="col-9" v-if="scenario.maleficenceType === null">
                  </div>
                  <div class="row">
                    <!-- 최종노출수준 적용기준 -->
                    <p class="font2">{{$label('LBL0001209')}}</p>
                    <div class="col-3">
                      <!-- 노출수준 -->
                      <c-text
                        :editable="false"
                        label="LBL0001142"
                        name="exposureLevelCombination"
                        v-model="scenario.exposureLevelCombination"
                        style="margin-top:20px">
                      </c-text>
                    </div>
                    <div class="col-4">
                      <!-- 밀폐·환기상태 -->
                      <c-select
                        :disabled="disabled||!selectScenario"
                        :editable="editable"
                        label="LBL0001143"
                        name="closedVentilation"
                        v-model="scenario.closedVentilation"
                        @input="cardDataChange('closedVentilation')"
                        codeGroupCd="CLOSED_VENTILATION"
                        itemText="codeName"
                        itemValue="code"
                        style="margin-top:20px">
                      </c-select>
                    </div>
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-6">
              <!-- 유해성 측정 -->
              <c-card title="LBL0001144" class="cardClassDetailForm">
                <template slot="card-detail">
                  <div class="col-6">
                    <!-- 1순위 -->
                    <p class="font3">{{$label('LBL0001206')}}</p>
                    <!-- CMR여부 -->
                    <c-checkbox
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isFlag="true"
                      label="LBL0001152"
                      name="cmrFlag"
                      @input="cardDataChange('cmrFlag')"
                      v-model="scenario.cmrFlag"
                    />
                  </div>
                  <div class="col-8">
                  </div>
                  <div class="col-6">
                    <!-- 2순위 노출기준 -->
                    <p class="font4">{{$label('LBL0001210')}}</p>
                    <!-- 발생형태 -->
                    <c-select
                      type="edit"
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001138"
                      name="maleficenceForm"
                      v-model="scenario.maleficenceForm"
                      codeGroupCd="MALEFICENCE_FORM"
                      itemText="codeName"
                      itemValue="code"
                      style="margin-top: 15px;">
                    </c-select>
                  </div>
                  <div class="col-6">
                    <!-- 측정값 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001146"
                      name="maleficenceValue"
                      @dataChange="cardDataChange('maleficenceValue')"
                      v-model="scenario.maleficenceValue"
                      style="margin-top: 35px;">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 3순위 위험문구 -->
                    <p class="font4">{{$label('LBL0001211')}}</p>
                    <!-- 위험문구/유해·위험문구 -->
                    <c-select
                      type="edit"
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001147"
                      name="hrCodeGrade"
                      v-model="scenario.hrCodeGrade"
                      codeGroupCd="HR_CODE_GRADE"
                      itemText="codeName"
                      itemValue="code"
                      @input="cardDataChange('hrCodeGrade')"
                      style="margin-top: 15px;">
                    </c-select>
                  </div>
                </template>
              </c-card>
              <!-- 최종 유해성 결과 -->
              <c-card title="LBL0001212" class="cardClassDetailForm" topClass="topcolor-orange">
                <template slot="card-detail">
                  <div class="col-12">
                    <q-list dense bordered separator>
                      <q-item>
                        <div class="card-risk-info-charm detail-risk">
                          <q-item-section>
                            <!-- 최종 노출수준 -->
                            <q-item-label>{{$label('LBL0001212')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.exposureLevelFinal}}</q-item-label>
                          </q-item-section>
                        </div>
                        <q-separator spaced vertical />
                        <div class="card-risk-info-charm detail-risk">
                          <q-item-section>
                            <!-- 유해성 -->
                            <q-item-label>{{$label('LBL0001127')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.toxic}}</q-item-label>
                          </q-item-section>
                        </div>
                      </q-item>
                      <!-- <q-separator spaced inset /> -->
                      <q-item class="detail-risk">
                        <q-item-section>
                          <!-- 위험도 추정 -->
                          <q-item-label>{{$label('LBL0001125')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side class="detail-risk-num-charm">
                          <q-item-label>{{scenario.riskEstimation}}</q-item-label>
                        </q-item-section>                        
                      </q-item>
                    </q-list><br/><br/>
                  </div>
                </template>
              </c-card>
            </div>
          </div>
        </q-form>
      </div>
      <c-win-pop 
        ref="winPopup"  
        @onClose="val=>evtCloseWinPopup(val)"  
        @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'charm-risk-estimation',

  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workCycleName: '',  // 작업발생주기
        workingTime: 0,  // 작업시간(1회)
        ramMatrixId: '',
        temperature: '',
        workMeasurementPlanId: '',
        measPlanContent: '',
        estimationFlag: '',
        charmResultScenarios: [],
        charmEstimationScenarios: [],
        estimationReCheckedCnt:0,
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramTechniqueCd:'',
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    estimationUpdateBtnData: {
      type: Object,
      default: () => ({
        title: '추정',
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      isCmrFlag: false,
      isCardSelect: false,
      grid: {
        merge: [
          { index: 0, colName: 'underProcessName' },
          { index: 1, colName: 'underProcessMaterialName' },
        ],
        data: [],
        height: '260px'
      },
      cardColumn: [
        {
          name: 'underProcessName',
          field: 'underProcessName',
          label: '단위공정',
          align: 'center',
          style: 'width:90px',
          sortable: false,
        },
        {
          name: 'materialName',
          field: 'materialName',
          // 화학자재
          label: 'LBL0000509',
          align: 'left',
          style: 'width:90px',
          sortable: false,
        },
        {
          name: 'chemName',
          field: 'chemName',
          // 구성물질
          label: 'LBL0001123',
          align: 'left',
          style: 'width:200px',
          sortable: false,
        },
        {
          name: 'item',
          field: 'item',
          // CAS No.
          label: 'LBL0001124',
          align: 'center',
          style: 'width:130px',
          sortable: false,
          type: 'custom'
        },
      ],
      gridColumn: [],
      scenarioViewGroup: 'L',
      volumnItems: [],
      inTypeItems: [],
      editable: true,
      isSave: false,
      isSaveUnit: false,
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      saveUnitUrl: '',
      resultUpdateUrl: '',
      activeWinProps: {
        processCd: '',
        ramCharmEstimationAssessScenarioId: '',
      },
      scenario: {
        ramCharmEstimationAssessScenarioId: '', // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정
        underProcessCd: '',  // 단위공정 코드
        underProcessName: '',  // 단위공정
        materialName:'', // 화학자재
        chemName:'', // 구성물질
        casNo:'', // CAS No
        riskEstimation: '', // 위험도 추정
        exposureLevelFinal: '', // 최종노출수준
        toxic: '', // 유해성
        suspectUserFlag:'', // 유소견자 발생여부
        workMeasure: '', // 작업환경측정
        measValue: '', // 측정치(A)
        exposureStandard: '', // 노출기준(B)
        exposureMeasValue: '', //A/B(%)
        daily: '', // 하루 취급량, 비산성/휘발성, 밀폐·환기상태
        limitRepval:'', // 함유량
        dailyVolume:'', //일취급량
        volumeUnitName: '', // 단위
        dailyLevel: '', // 일취급 수준
        maleficenceType: null, //발생형태
        maleficenceGrade: null, //비산성
        boilingPoint: '', //끓는점
        volatilityGradeName: '', // 휘발성
        exposureLevelCombination: '', //노출수준
        closedVentilation: '0', // 밀폐·환기상태
        cmrFlag: '', // CMR여부
        measureCheck: '', // 노출기준확인
        maleficenceForm: null, //발생형태
        maleficenceValue: '', // 측정값
        hrCodeGrade: null, // 위험문구/유해·위험문구
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isCompared() {
      return this.process.estimationReCheckedCnt > 0;
    },
    isMaleficenceType1() {
      return this.scenario.maleficenceType !== null && !this.maleficenceTypeFlag;
    },
    isMaleficenceType2() {
      return this.scenario.maleficenceType !== null && this.maleficenceTypeFlag;
    },
    disabled() {
      return (!(this.param.ramStepCd === 'RRS0000005' || this.param.ramStepCd == 'RRS0000010') 
        || this.process.ramProcessAssessStepCd === 'RPA0000005' 
        || this.process.estimationFlag === 'Y') && !this.estimationUpdateBtnData.flag
    },
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 130) + 'px' : '500px'
    },
    estimationBtnEditable() {
      return this.editable 
        && this.param.ramStepCd === 'RRS0000010' 
        && this.process.estimationFlag === 'Y'
        && this.process.ramProcessAssessStepCd !== 'RPA0000005'
    },
    flagCondition() {
      // update 버튼 활성화 여부
      return this.param.ramStepCd === 'RRS0000010' // 감소대책 계획 및 실행 상태인 경우
        && this.process.estimationFlag === 'N' // 추정완료되지 않은 경우
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' // 공정완료 되지 않은 경우
    },
    selectScenario() {
      return Boolean(this.scenario.ramCharmEstimationAssessScenarioId)
    },
    scenarioTarget() {
      return this.selectScenario ? `${this.scenario.materialName} ` : '';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.charm.scenario.estimation.url;
      this.detailUrl = selectConfig.ram.charm.scenario.estimationDetail.url;
      this.saveUrl = transactionConfig.ram.charm.scenario.estimation.url;
      this.saveUnitUrl = transactionConfig.ram.charm.scenario.estimationSave.url;
      this.resultUpdateUrl = transactionConfig.ram.assessProcess.estimation.url;
      // list setting
      this.setRisk();
      // code setting
    },
    listView() {
      this.scenarioViewGroup = 'L'
      this.grid.columns = this.gridColumn;
    },
    cardView() {
      this.scenarioViewGroup = 'C'
      this.grid.columns = this.cardColumn;
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd,
      }
      this.$http.request((_result) => {
        this.process.charmEstimationScenarios = _result.data;
      },);
    },
    getDetail(ramCharmEstimationAssessScenarioId) {
      this.$http.url = this.$format(this.detailUrl, ramCharmEstimationAssessScenarioId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);

        if (this.scenario.maleficenceType == 'MT00000001') {
          this.maleficenceTypeFlag = false;
        } else {
          this.maleficenceTypeFlag = true;
        }
        this.isCardSelect = true;
      },);
    },
    setRisk() {
      this.$comm.getMultiComboItems(['MALEFICENCE_TYPE'
        , 'CLOSED_VENTILATION'
        , 'MALEFICENCE_GRADE'
        , 'MALEFICENCE_FORM'
        , 'HR_CODE_GRADE'
        , 'VOLUME_UNIT']).then(_result => {
        this.volumnItems = _result['VOLUME_UNIT']
        this.gridColumn = [
          {
            fix: true,
            name: 'underProcessName',
            field: 'underProcessName',
            label: '단위공정',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: 'LBL0000356',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'chemName',
            field: 'chemName',
            // 구성물질
            label: 'LBL0001123',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'casNo',
            field: 'casNo',
            // CAS No.
            label: 'LBL0001124',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskEstimation',
            field: 'riskEstimation',
            // 위험도 추정
            label: 'LBL0001125',
            align: 'center',
            style: 'width:50px',
            sortable: false,
            color: '#1976D2'
          },
          {
            name: 'exposureLevelFinal',
            field: 'exposureLevelFinal',
            // 최종노출수준
            label: 'LBL0001126',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            color: '#1976D2'
          },
          {
            name: 'toxic',
            field: 'toxic',
            // 유해성
            label: 'LBL0001127',
            align: 'center',
            style: 'width:60px',
            sortable: false,
            color: '#1976D2'
          },
          {
            name: 'f',
            field: 'f',
            // 노출수준 측정
            label: 'LBL0001128',
            child: [
              {
                name: 'suspectUserFlag',
                field: 'suspectUserFlag',
                // 유소견자 발생여부
                label: 'LBL0001129',
                align: 'center',
                style: 'width:70px',
                sortable: false,
                type: 'check',
                true: 'Y',
                false: 'N'
              },
              {
                name: 'workMeasure',
                field: 'workMeasure',
                // 작업환경측정
                label: 'LBL0001130',
                child: [
                  {
                    name: 'measValue',
                    field: 'measValue',
                    // 측정치(A)
                    label: 'LBL0001131',
                    align: 'right',
                    style: 'width:80px',
                    sortable: false,
                    type: 'number'
                  },
                  {
                    name: 'exposureStandard',
                    field: 'exposureStandard',
                    // 노출기준(B)
                    label: 'LBL0001132',
                    align: 'right',
                    style: 'width:80px',
                    sortable: false,
                    type: 'number'
                  },
                  {
                    name: 'exposureMeasValue',
                    field: 'exposureMeasValue',
                    // A/B(%)
                    label: 'LBL0001133',
                    align: 'right',
                    style: 'width:65px',
                    sortable: false,
                    type: 'cost'
                  },
                ]
              },
              {
                name: 'daily',
                field: 'daily',
                // 하루 취급량, 비산성/휘발성, 밀폐·환기상태
                label: 'LBL0001134',
                child: [
                  {
                    name: 'limitRepval',
                    field: 'limitRepval',
                    // 함유량
                    label: 'LBL0001135',
                    align: 'center',
                    style: 'width:60px',
                    sortable: false,
                    type: 'number'
                  },
                  {
                    name: 'dailyVolume',
                    field: 'dailyVolume',
                    // 일취급량
                    label: 'LBL0001136',
                    align: 'center',
                    style: 'width:70px',
                    sortable: false,
                  },
                  {
                    name: 'volumeUnitName',
                    field: 'volumeUnitName',
                    label: 'LBLUNIT',
                    align: 'center',
                    style: 'width:50px',
                    sortable: false,
                  },
                  {
                    name: 'dailyLevel',
                    field: 'dailyLevel',
                    // 일취급 수준
                    label: 'LBL0001137',
                    align: 'center',
                    style: 'width:50px',
                    sortable: false,
                  },
                  {
                    name: 'maleficenceType',
                    field: 'maleficenceType',
                    // 발생형태
                    label: 'LBL0001138',
                    align: 'center',
                    style: 'width:80px',
                    sortable: false,
                    type: 'select',
                    comboItems: _result['MALEFICENCE_TYPE']
                  },
                  {
                    name: 'maleficenceGrade',
                    field: 'maleficenceGrade',
                    // 비산성
                    label: 'LBL0001139',
                    align: 'center',
                    style: 'width:80px',
                    sortable: false,
                    disableTarget: 'maleficenceType',
                    disableCon: 'MT00000001',
                    type: 'select',
                    comboItems: _result['MALEFICENCE_GRADE']
                  },
                  {
                    name: 'boilingPoint',
                    field: 'boilingPoint',
                    // 끓는점
                    label: 'LBL0001140',
                    align: 'right',
                    style: 'width:60px',
                    sortable: false,
                    type: 'number',
                    disableTarget: 'maleficenceType',
                    disableCon: 'MT00000002',
                  },
                  {
                    name: 'volatilityGradeName',
                    field: 'volatilityGradeName',
                    // 휘발성
                    label: 'LBL0001141',
                    align: 'center',
                    style: 'width:60px',
                    sortable: false,
                  },
                  {
                    name: 'exposureLevelCombination',
                    field: 'exposureLevelCombination',
                    // 노출수준
                    label: 'LBL0001142',
                    align: 'center',
                    style: 'width:40px',
                    sortable: false,
                  },
                  {
                    name: 'closedVentilation',
                    field: 'closedVentilation',
                    // 밀폐·환기상태
                    label: 'LBL0001143',
                    align: 'center',
                    style: 'width:100px',
                    sortable: false,
                    type: 'select',
                    none: 'none',
                    comboItems: _result['CLOSED_VENTILATION']
                  },
                ],
              },
            ]
          },
          {
            name: 's',
            field: 's',
            // 유해성 측정
            label: 'LBL0001144',
            child: [
              {
                name: 'cmrFlag',
                field: 'cmrFlag',
                // CMR여부
                label: 'LBL0001152',
                align: 'center',
                style: 'width:80px',
                sortable: false,
                type: 'check',
                true: 'Y',
                false: 'N'
              },
              {
                name: 'measureCheck',
                field: 'measureCheck',
                // 노출기준확인
                label: 'LBL0001145',
                child: [
                  {
                    name: 'maleficenceForm',
                    field: 'maleficenceForm',
                    // 발생형태
                    label: 'LBL0001138',
                    align: 'center',
                    style: 'width:110px',
                    sortable: false,
                    disableTarget: 'cmrFlag',
                    disableCon: 'N',
                    type: 'select',
                    comboItems: _result['MALEFICENCE_FORM']
                  },
                  {
                    name: 'maleficenceValue',
                    field: 'maleficenceValue',
                    // 측정값
                    label: 'LBL0001146',
                    align: 'right',
                    style: 'width:80px',
                    sortable: false,
                    disableTarget: 'cmrFlag',
                    disableCon: 'N',
                    type: 'number'
                  },
                ]
              },
              {
                name: 'hrCodeGrade',
                field: 'hrCodeGrade',
                // 위험문구/유해·위험문구
                label: 'LBL0001147',
                align: 'center',
                style: 'width:80px',
                sortable: false,
                disableTarget: 'cmrFlag',
                disableCon: 'N',
                type: 'select',
                comboItems: _result['HR_CODE_GRADE']
              },
            ]
          },
        ]
        this.grid.columns = this.gridColumn;
        this.getList();
      });
    },
    tableDataChange(props, col) {
      if (col.name === 'maleficenceType') {
        if (props.row[col.name] === 'MT00000002') {
          this.$set(props.row, 'maleficenceGrade', null)
          if (props.row.boilingPoint) {
            // 끓는점이 있는 경우 : 공정사용온도는 필수 체크후 넘어왔음으로 있다고 판단
            // 상온 여부 체크
            if (this.process.temperature !== 20) {
              this.$set(props.row, 'volatilityGrade', props.row.boilingPoint < (this.process.temperature*2 + 10) ? '3' :
                props.row.boilingPoint >= (this.process.temperature*2 + 10) && props.row.boilingPoint <= (this.process.temperature*5 + 50) ? '2' : '1')
            } else {
              this.$set(props.row, 'volatilityGrade', props.row.boilingPoint < 50 ? '3' :
                props.row.boilingPoint >= 50 && props.row.boilingPoint <= 150 ? '2' : '1')
            }
            this.$set(props.row, 'volatilityGradeName', props.row.volatilityGrade === '3' ? '3(고)' : 
              props.row.volatilityGrade === '2' ? '2(중)' : '1(저)')
          } else {
            // 노출기준 초기화 처리
            this.$set(props.row, 'volatilityGrade', '0')
            this.$set(props.row, 'volatilityGradeName', '')
          }
        } else {
          this.$set(props.row, 'boilingPoint', '')
          this.$set(props.row, 'volatilityGradeName', '')
        }
      } else if (col.name === 'boilingPoint') {
        // 끓는점 데이터가 들어온 경우
        // 공정사용온도는 필수 체크후 넘어왔음으로 있다고 판단
        // 상온 여부 체크
        if (props.row[col.name]) {
          if (this.process.temperature !== 20) {
            this.$set(props.row, 'volatilityGrade', props.row.boilingPoint < (this.process.temperature*2 + 10) ? '3' :
              props.row.boilingPoint >= (this.process.temperature*2 + 10) && props.row.boilingPoint <= (this.process.temperature*5 + 50) ? '2' : '1')
          } else {
            this.$set(props.row, 'volatilityGrade', props.row.boilingPoint < 50 ? '3' :
              props.row.boilingPoint >= 50 && props.row.boilingPoint <= 150 ? '2' : '1')
          }
          this.$set(props.row, 'volatilityGradeName', props.row.volatilityGrade === '3' ? '3(고)' : 
            props.row.volatilityGrade === '2' ? '2(중)' : '1(저)')

        } else {
          this.$set(props.row, 'volatilityGrade', '0')
          this.$set(props.row, 'volatilityGradeName', '')
        }
      } else if (col.name === 'measValue' || col.name === 'exposureStandard') {
        /**
         * 작업환경측정 :: 해당 부분은 업체를 통해 측정값을 받아 계산하는 로직이 들어가며
         *                기본적으로 해당 측정치를 사용자가 마음대로 바꾸는것은 안됨
         *                LNF 및 솔루션에서는 작업환경측정 데이터가 안 들어올 수 있다는 가정하에 입력을 받게 처리
         */
        let _exposureMeasValue = (props.row.exposureStandard && !isNaN(props.row.exposureStandard) && Number(props.row.exposureStandard) !== 0) ? ((props.row.measValue && !isNaN(props.row.measValue) ? Number(props.row.measValue) : 0) * 100 / Number(props.row.exposureStandard)) : 0;
        this.$set(props.row, 'exposureMeasValue', this.$_.ceil(_exposureMeasValue, 2))
      } else if (col.name === 'limitRepval') {
        /**
         * 함유량 :: 화학자재 마스터에서 관리하는 구성성분의 함유량을 토대로 일취급수준을 결정
         *          단 함유량 대표값을 정확히 판단하기 어려운 경우를 위해 key-in이 되도록 설정
         */
        if (props.row.limitRepval && !isNaN(props.row.limitRepval)) {
          let chemprod = this.$_.find(this.process.charmResultScenarios, { mdmChemMaterialId: props.row.mdmChemMaterialId });
          let _dailyVolume = this.$_.round(Number(chemprod.dailyVolume) * (Number(props.row.limitRepval) / 100), 4); // 물질의 일취급량
          let _volumeObj = this.$_.find(this.volumnItems, { code: chemprod.volumeUnit });
          let _volumeUnit = _volumeObj.code;
          let _volumeUnitName = _volumeObj.codeName;
          switch(chemprod.volumeUnit) {
            case '1': // g, ㎖
              if (_dailyVolume >= 1000 && _dailyVolume < 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '2';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '2' }).codeName;
              } else if (_dailyVolume >= 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '3';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '3' }).codeName;
              }
              break;
            case '4': // g, ㎖
              if (_dailyVolume >= 1000 && _dailyVolume < 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '5';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '5' }).codeName;
              } else if (_dailyVolume >= 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '6';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '6' }).codeName;
              }
              break;
            case '2': // ㎏, ℓ
              if (_dailyVolume >= 1000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '3';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '3' }).codeName;
              } else if (_dailyVolume < 1) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '1';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '1' }).codeName;
              }
              break;
            case '5': // ㎏, ℓ
              if (_dailyVolume >= 1000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '6';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '6' }).codeName;
              } else if (_dailyVolume < 1) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '4';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '4' }).codeName;
              } 
              break;
            case '3': // ton, ㎥
              if (_dailyVolume < 1 && _dailyVolume >= 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '2';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '2' }).codeName;
              } else if (_dailyVolume < 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000 * 1000, 2)
                _volumeUnit = '1';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '1' }).codeName;
              }
              break;
            case '6': // ton, ㎥
              if (_dailyVolume < 1 && _dailyVolume >= 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '5';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '5' }).codeName;
              } else if (_dailyVolume < 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000 * 1000, 2)
                _volumeUnit = '4';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '4' }).codeName;
              }
              break;
          }
          this.$set(props.row, 'dailyVolume', _dailyVolume)
          this.$set(props.row, 'volumeUnit', _volumeUnit)
          this.$set(props.row, 'volumeUnitName', _volumeUnitName)
          this.$set(props.row, 'dailyLevel', _volumeUnit)
        } else {
          this.$set(props.row, 'dailyVolume', 0)
          this.$set(props.row, 'volumeUnit', null)
          this.$set(props.row, 'volumeUnitName', null)
          this.$set(props.row, 'dailyLevel', '0')
        }
      }
      
      this.riskEstimationCheck(props)
    },
    riskEstimationCheck(props) {
      // 노출 기준 측정
      if (props.row.suspectUserFlag === 'Y') {
        // 1. 유소견자가 있는 경우
        this.$set(props.row, 'exposureLevelCombination', '4')
      } else if (props.row.exposureMeasValue) {
        // 2. 작업환경측정 정보가 있는 경우
        let num = Number(props.row.exposureMeasValue)
        this.$set(props.row, 'exposureLevelCombination', num > 100 ? '4' :
          num > 50 && num <= 100 ? '3' :
          num > 10 && num <= 50 ? '2' : '1')
      } else {
        // 3. 비산성 / 휘발성 체크
        if (props.row['maleficenceType'] === 'MT00000001') {
          if (props.row.dailyLevel === '3') {
            this.$set(props.row, 'exposureLevelCombination', props.row.maleficenceGrade === '3' ? '4' : 
              props.row.maleficenceGrade === '2' ? '4' : 
              props.row.maleficenceGrade === '1' ? '2' : '0')
          } else if (props.row.dailyLevel === '2') {
            this.$set(props.row, 'exposureLevelCombination', props.row.maleficenceGrade === '3' ? '3' : 
              props.row.maleficenceGrade === '2' ? '3' : 
              props.row.maleficenceGrade === '1' ? '2' : '0')
          } else if (props.row.dailyLevel === '1') {
            this.$set(props.row, 'exposureLevelCombination', props.row.maleficenceGrade === '3' ? '2' : 
              props.row.maleficenceGrade === '2' ? '1' : 
              props.row.maleficenceGrade === '1' ? '1' : '0')
          } else {
            this.$set(props.row, 'exposureLevelCombination', '0')
          }
        } else if (props.row['maleficenceType'] === 'MT00000002') {          
          if (props.row.dailyLevel === '3') {
            this.$set(props.row, 'exposureLevelCombination', props.row.volatilityGrade === '3' ? '4' : 
              props.row.volatilityGrade === '2' ? '3' : 
              props.row.volatilityGrade === '1' ? '2' : '0')
          } else if (props.row.dailyLevel === '2') {
            this.$set(props.row, 'exposureLevelCombination', props.row.volatilityGrade === '3' ? '3' : 
              props.row.volatilityGrade === '2' ? '3' : 
              props.row.volatilityGrade === '1' ? '2' : '0')
          } else if (props.row.dailyLevel === '1') {
            this.$set(props.row, 'exposureLevelCombination', props.row.volatilityGrade === '3' ? '2' : 
              props.row.volatilityGrade === '2' ? '2' : 
              props.row.volatilityGrade === '1' ? '1' : '0')
          } else {
            this.$set(props.row, 'exposureLevelCombination', '0')
          }
        } else {
          // 선택되지 않은 경우
          this.$set(props.row, 'exposureLevelCombination', '0')
        }
      }
      this.$set(props.row, 'exposureLevelFinal', String(Number(props.row.exposureLevelCombination) - Number(props.row.closedVentilation)))

      // 유해성 측정
      if (props.row.cmrFlag === 'Y') {
        // 1. CMR 물질 여부
        this.$set(props.row, 'toxic', '4')
      } else if (props.row.maleficenceValue && props.row.maleficenceForm) {
        // 2. 발생형태에 따른 측정값 체크
        /**
         * 노출값 체크
         * 10㎎/㎥(분진) 또는 500ppm(증기)을 초과 여부
         */
        let checkVal = props.row.maleficenceForm === '분진' ? props.row.maleficenceValue <= 10 : props.row.maleficenceValue <= 500
        if (checkVal) {
          let toxic = function(maleficenceValue, constVal) {
            return maleficenceValue <= 0.01*constVal ? '4' :
              maleficenceValue > 0.01*constVal && maleficenceValue <= 0.1*constVal ? '3' :
              maleficenceValue > 0.1*constVal && maleficenceValue <= 1*constVal ? '2' : '1'
          }
          this.$set(props.row, 'toxic', (props.row.maleficenceForm === '분진' ? toxic(props.row.maleficenceValue, 1) : toxic(props.row.maleficenceValue, 50)))
        } else {
          // 초과한 경우 유해위험문구를 체크
          this.$set(props.row, 'toxic', (props.row.hrCodeGrade ? props.row.hrCodeGrade : '0'))
        }
      } else {
        // 유해위험문구
        this.$set(props.row, 'toxic', (props.row.hrCodeGrade ? props.row.hrCodeGrade : '0'))
      }
      // 위험도 추정
      this.$set(props.row, 'riskEstimation', String((props.row.exposureLevelFinal && !isNaN(props.row.exposureLevelFinal) ? Number(props.row.exposureLevelFinal) : 0) * (props.row.toxic && !isNaN(props.row.toxic) ? Number(props.row.toxic) : 0)))
    },
    cardDataChange(_type) {
      if (_type == 'maleficenceType') { // 발생형태
        if (this.scenario.maleficenceType === 'MT00000002') { //휘발성일 경우
          this.maleficenceTypeFlag = true;
          this.$set(this.scenario, 'maleficenceGrade', null)
          if (this.scenario.boilingPoint) {
            // 끓는점이 있는 경우 : 공정사용온도는 필수 체크후 넘어왔음으로 있다고 판단
            // 상온 여부 체크
            if (this.process.temperature !== 20) {
              this.$set(this.scenario, 'volatilityGrade', this.scenario.boilingPoint < (this.process.temperature*2 + 10) ? '3' :
              this.scenario.boilingPoint >= (this.process.temperature*2 + 10) && this.scenario.boilingPoint <= (this.process.temperature*5 + 50) ? '2' : '1')
            } else {
              this.$set(this.scenario, 'volatilityGrade', this.scenario.boilingPoint < 50 ? '3' :
              this.scenario.boilingPoint >= 50 && this.scenario.boilingPoint <= 150 ? '2' : '1')
            }
            this.$set(this.scenario, 'volatilityGradeName', this.scenario.volatilityGrade === '3' ? '3(고)' : 
            this.scenario.volatilityGrade === '2' ? '2(중)' : '1(저)')
          } else {
            // 노출기준 초기화 처리
            this.$set(this.scenario, 'volatilityGrade', '0')
            this.$set(this.scenario, 'volatilityGradeName', '')
          }
        } else {
          this.maleficenceTypeFlag = false;
        } 
      } else if (_type == 'boilingPoint') { // 끓는점
        // 끓는점 데이터가 들어온 경우
        // 공정사용온도는 필수 체크후 넘어왔음으로 있다고 판단
        // 상온 여부 체크
        if (this.scenario.boilingPoint) {
          if (this.process.temperature !== 20) {
            this.$set(this.scenario, 'volatilityGrade', this.scenario.boilingPoint < (this.process.temperature*2 + 10) ? '3' :
            this.scenario.boilingPoint >= (this.process.temperature*2 + 10) && this.scenario.boilingPoint <= (this.process.temperature*5 + 50) ? '2' : '1')
          } else {
            this.$set(this.scenario, 'volatilityGrade', this.scenario.boilingPoint < 50 ? '3' :
            this.scenario.boilingPoint >= 50 && this.scenario.boilingPoint <= 150 ? '2' : '1')
          }
          this.$set(this.scenario, 'volatilityGradeName', this.scenario.volatilityGrade === '3' ? '3(고)' : 
          this.scenario.volatilityGrade === '2' ? '2(중)' : '1(저)')

        } else {
          this.$set(this.scenario, 'volatilityGrade', '0')
          this.$set(this.scenario, 'volatilityGradeName', '')
        }
      } else if (_type == 'measValue' || _type == 'exposureStandard') {
        /**
         * 작업환경측정 :: 해당 부분은 업체를 통해 측정값을 받아 계산하는 로직이 들어가며
         *                기본적으로 해당 측정치를 사용자가 마음대로 바꾸는것은 안됨
         *                LNF 및 솔루션에서는 작업환경측정 데이터가 안 들어올 수 있다는 가정하에 입력을 받게 처리
         */
        let _exposureMeasValue = (this.scenario.exposureStandard && !isNaN(this.scenario.exposureStandard) && Number(this.scenario.exposureStandard) !== 0) ? ((this.scenario.measValue && !isNaN(this.scenario.measValue) ? Number(this.scenario.measValue) : 0) * 100 / Number(this.scenario.exposureStandard)) : 0;
        this.$set(this.scenario, 'exposureMeasValue', this.$_.ceil(_exposureMeasValue, 2))
      } else if (_type == 'limitRepval') {
        /**
         * 함유량 :: 화학자재 마스터에서 관리하는 구성성분의 함유량을 토대로 일취급수준을 결정
         *          단 함유량 대표값을 정확히 판단하기 어려운 경우를 위해 key-in이 되도록 설정
         */
        if (this.scenario.limitRepval && !isNaN(this.scenario.limitRepval)) {
          let chemprod = this.$_.find(this.process.charmResultScenarios, { mdmChemMaterialId: this.scenario.mdmChemMaterialId });
          let _dailyVolume = this.$_.round(Number(chemprod.dailyVolume) * (Number(this.scenario.limitRepval) / 100), 4); // 물질의 일취급량
          let _volumeObj = this.$_.find(this.volumnItems, { code: chemprod.volumeUnit });
          let _volumeUnit = _volumeObj.code;
          let _volumeUnitName = _volumeObj.codeName;
          switch(chemprod.volumeUnit) {
            case '1': // g, ㎖
              if (_dailyVolume >= 1000 && _dailyVolume < 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '2';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '2' }).codeName;
              } else if (_dailyVolume >= 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '3';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '3' }).codeName;
              }
              break;
            case '4': // g, ㎖
              if (_dailyVolume >= 1000 && _dailyVolume < 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '5';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '5' }).codeName;
              } else if (_dailyVolume >= 1000000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '6';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '6' }).codeName;
              }
              break;
            case '2': // ㎏, ℓ
              if (_dailyVolume >= 1000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '3';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '3' }).codeName;
              } else if (_dailyVolume < 1) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '1';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '1' }).codeName;
              }
              break;
            case '5': // ㎏, ℓ
              if (_dailyVolume >= 1000) {
                _dailyVolume = this.$_.round(_dailyVolume / 1000, 2)
                _volumeUnit = '6';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '6' }).codeName;
              } else if (_dailyVolume < 1) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '4';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '4' }).codeName;
              }
              break;
            case '3': // ton, ㎥
              if (_dailyVolume < 1 && _dailyVolume >= 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '2';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '2' }).codeName;
              } else if (_dailyVolume < 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000 * 1000, 2)
                _volumeUnit = '1';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '1' }).codeName;
              }
              break;
            case '6': // ton, ㎥
              if (_dailyVolume < 1 && _dailyVolume >= 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000, 2)
                _volumeUnit = '5';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '5' }).codeName;
              } else if (_dailyVolume < 0.001) {
                _dailyVolume = this.$_.round(_dailyVolume * 1000 * 1000, 2)
                _volumeUnit = '4';
                _volumeUnitName = this.$_.find(this.volumnItems, { code: '4' }).codeName;
              }
              break;
          }
          this.$set(this.scenario, 'dailyVolume', _dailyVolume)
          this.$set(this.scenario, 'volumeUnit', _volumeUnit)
          this.$set(this.scenario, 'volumeUnitName', _volumeUnitName)
          this.$set(this.scenario, 'dailyLevel', _volumeUnit)
        } else {
          this.$set(this.scenario, 'dailyVolume', 0)
          this.$set(this.scenario, 'volumeUnit', null)
          this.$set(this.scenario, 'volumeUnitName', null)
          this.$set(this.scenario, 'dailyLevel', '0')
        }
      }

      this.cardRiskEstimationCheck()
    },
    cardRiskEstimationCheck() {
      // 노출 기준 측정
      if (this.scenario.suspectUserFlag === 'Y') {
        // 1. 유소견자가 있는 경우
        this.$set(this.scenario, 'exposureLevelCombination', '4')
      } else if (this.scenario.exposureMeasValue) {
        // 2. 작업환경측정 정보가 있는 경우
        let num = Number(this.scenario.exposureMeasValue)
        this.$set(this.scenario, 'exposureLevelCombination', num > 100 ? '4' :
          num > 50 && num <= 100 ? '3' :
          num > 10 && num <= 50 ? '2' : '1')
      } else {
        // 3. 비산성 / 휘발성 체크
        if (this.scenario.maleficenceType === 'MT00000001') {
          if (this.scenario.dailyLevel === '3') {
            this.$set(this.scenario, 'exposureLevelCombination', this.scenario.maleficenceGrade === '3' ? '4' : 
              this.scenario.maleficenceGrade === '2' ? '4' : 
              this.scenario.maleficenceGrade === '1' ? '2' : '0')
          } else if (this.scenario.dailyLevel === '2') {
            this.$set(this.scenario, 'exposureLevelCombination', this.scenario.maleficenceGrade === '3' ? '3' : 
              this.scenario.maleficenceGrade === '2' ? '3' : 
              this.scenario.maleficenceGrade === '1' ? '2' : '0')
          } else if (this.scenario.dailyLevel === '1') {
            this.$set(this.scenario, 'exposureLevelCombination', this.scenario.maleficenceGrade === '3' ? '2' : 
              this.scenario.maleficenceGrade === '2' ? '1' : 
              this.scenario.maleficenceGrade === '1' ? '1' : '0')
          } else {
            this.$set(this.scenario, 'exposureLevelCombination', '0')
          }
        } else if (this.scenario.maleficenceType === 'MT00000002') {          
          if (this.scenario.dailyLevel === '3') {
            this.$set(this.scenario, 'exposureLevelCombination', this.scenario.volatilityGrade === '3' ? '4' : 
              this.scenario.volatilityGrade === '2' ? '3' : 
              this.scenario.volatilityGrade === '1' ? '2' : '0')
          } else if (this.scenario.dailyLevel === '2') {
            this.$set(this.scenario, 'exposureLevelCombination', this.scenario.volatilityGrade === '3' ? '3' : 
              this.scenario.volatilityGrade === '2' ? '3' : 
              this.scenario.volatilityGrade === '1' ? '2' : '0')
          } else if (this.scenario.dailyLevel === '1') {
            this.$set(this.scenario, 'exposureLevelCombination', this.scenario.volatilityGrade === '3' ? '2' : 
              this.scenario.volatilityGrade === '2' ? '2' : 
              this.scenario.volatilityGrade === '1' ? '1' : '0')
          } else {
            this.$set(this.scenario, 'exposureLevelCombination', '0')
          }
        } else {
          // 선택되지 않은 경우
          this.$set(this.scenario, 'exposureLevelCombination', '0')
        }
      }
      this.$set(this.scenario, 'exposureLevelFinal', String(Number(this.scenario.exposureLevelCombination) - Number(this.scenario.closedVentilation)))

      // 유해성 측정
      if (this.scenario.cmrFlag === 'Y') {
        // 1. CMR 물질 여부
        this.$set(this.scenario, 'toxic', '4')
        this.$set(this.scenario, 'maleficenceForm', null)
        this.$set(this.scenario, 'maleficenceValue', null)
        this.$set(this.scenario, 'hrCodeGrade', null)
      } else if (this.scenario.maleficenceValue && this.scenario.maleficenceForm) {
        // 2. 발생형태에 따른 측정값 체크
        /**
         * 노출값 체크
         * 10㎎/㎥(분진) 또는 500ppm(증기)을 초과 여부
         */
        let checkVal = this.scenario.maleficenceForm === '분진' ? this.scenario.maleficenceValue <= 10 : this.scenario.maleficenceValue <= 500

        if (checkVal) {
          let toxic = function(maleficenceValue, constVal) {
            return maleficenceValue <= 0.01*constVal ? '4' :
              maleficenceValue > 0.01*constVal && maleficenceValue <= 0.1*constVal ? '3' :
              maleficenceValue > 0.1*constVal && maleficenceValue <= 1*constVal ? '2' : '1'
          }
          this.$set(this.scenario, 'toxic', (this.scenario.maleficenceForm === '분진' ? toxic(this.scenario.maleficenceValue, 1) : toxic(this.scenario.maleficenceValue, 50)))
        } else {
          // 초과한 경우 유해위험문구를 체크
          this.$set(this.scenario, 'toxic', (this.scenario.hrCodeGrade ? this.scenario.hrCodeGrade : '0'))
        }
      } else {
        // 유해위험문구
        this.$set(this.scenario, 'toxic', (this.scenario.hrCodeGrade ? this.scenario.hrCodeGrade : '0'))
      }
      // 위험도 추정
      this.$set(this.scenario, 'riskEstimation', String((this.scenario.exposureLevelFinal && !isNaN(this.scenario.exposureLevelFinal) ? Number(this.scenario.exposureLevelFinal) : 0) * (this.scenario.toxic && !isNaN(this.scenario.toxic) ? Number(this.scenario.toxic) : 0)))
    },
    research() {
      this.$emit('research', 'scenario')
    },
    /* eslint-disable no-unused-vars */
    saveScenario() {
      if (this.$comm.validTable(this.grid.columns, this.process.charmEstimationScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();

      if (this.estimationUpdateBtnData.flag) {
        this.$http.url = this.resultUpdateUrl
        this.$http.type = 'PUT';
        this.$http.param = {
          ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
          processCd: this.process.processCd,
        };
        this.$http.request((_result) => {
          this.estimationUpdateBtnData.research = uid();
        },);
      }
    },
    saveScenarioUnit(){
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    compareScenario() {
      let uri = `/ram/compare/compareScenario?ramTechniqueCd=${this.param.ramTechniqueCd}&ramRiskAssessmentPlanId=${this.param.ramRiskAssessmentPlanId}&processCd=${this.process.processCd}&charmTypeName=estimation`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    setTrClass(props) {
      return props.row.processCd === this.activeWinProps.processCd 
        && props.row.compareId === this.activeWinProps.ramCharmEstimationAssessScenarioId ? 'bg-purple-2' : '';
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if(recvObj) {
        this.$set(this.activeWinProps, 'processCd', recvObj.processCd)
        this.$set(this.activeWinProps, 'ramCharmEstimationAssessScenarioId', recvObj.ramCharmEstimationAssessScenarioId)
      }
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.research();
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important

.font 
  color: red
  margin:0 0 20px 10px
  font-size:14px
  font-weight: bolder

.font2
  width: 500px
  color: red
  margin:0 0 5px 7px
  font-size:14px
  font-weight: bolder

.font3
  color: red
  margin:0 0 20px 10px
  font-size:14px 
  font-weight: bolder

.font4 
  color: red
  margin:0 0 5px 7px
  font-size:14px
  font-weight: bolder
</style>
